.input-range {
    border: 8px solid #000;
    height: 84px !important;
    border-radius: 130px;
    box-sizing: border-box;
    .input-range__label {
        display: none;
    }
    .input-range__track,
    .input-range__track--active {
        background-color: transparent;
    }
    .input-range__slider {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-color: #000;
        top: 10.5px;
        margin: 3px !important;
        border: none !important;
        transition: .4s all ease;
        &:hover,
        &:active {
            background-color: #D12FFF;
            width: 45px;
            height: 45px;
            transform: none !important;
        }

    }
    .input-range__track {
        height: 100% !important;
        top: 0 !important;
        box-sizing: border-box !important;
        max-width: calc(100% - 14px);
    }
    .input-range__track--activ {
        background-color: red !important;
    }
    .input-range__slider-container {
        height: 100%;
        top: 0;
    }
}
.input-range__slider__left {
    .input-range__slider {
        left: 8px;
    }
}
.input-range__slider__right {
    .input-range__slider{
        right: -8px;
    }
}