
@import "./Fonts.css";
@import "./InputRangeStyle.scss";
@import "./variables.scss";

* {
    -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
    box-sizing: border-box;
    &::after,
    &::before {
        box-sizing: border-box;
    }
}

body {
    font-family: 'HelveticaNowDisplay', serif;
    font-size: 1em;
    color: #000;
    line-height: 1.4;
    padding: 0;
    margin: 0;
    .app {
        padding: 5em 0 0 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 100vh;
        @media (min-width: 2501px) {
            padding: 100px 0 0 0;
        }

        @media (max-width: 991px) {
            padding: 60px 0 0 0;
        }
    }
    &.bodyFixed {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        min-height: 100vh;
    }
    &.fixedJobHeader {
        @media (max-width: $sizeTabletLg) {
            header {
                transform: none !important;
            }
        }
    }

    #container {
        height: unset !important;
    }
}

html {
    scroll-behavior: smooth;
    body {
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
            -webkit-text-fill-color:inherit;
            -webkit-box-shadow: none;
            box-shadow: none;
            transition: background-color 5000s ease-in-out 0s;
        }
    }
}
.pageWrapper {
    flex: 1 0 auto;
}
footer {
    flex-shrink: 0;
}
ul,ol {
    padding: 0;
    margin: 0;
    list-style-type: none;
    p {
        list-style-type: none
    }
}
p {
    margin: 0;
}
a:focus {
    outline: none;
}
a, a:hover {
    text-decoration: none;
}

/* Defaul Style */
h1 {
    font-size: 4.75em;
    text-transform: uppercase;
    margin: 0;
    font-family: 'HelveticaNowDisplay', serif;
    font-weight: 900;
    @media (max-width: $sizeDesktopMd) {
        font-size: 4.125em;
    }
    @media (max-width: $sizeTabletLg) {
        font-size: 3.5em;
    }
    @media (max-width: $sizeMobileMd) {
        font-size: 2.875em;
    }
    @media (max-width: $sizeMobileXs) {
        font-size: 2.25em;
    }
}
h2 {
    font-size: 4.375em;
    text-transform: uppercase;
    margin: 0;
    font-family: 'HelveticaNowDisplay', serif;
    @media (max-width: $sizeDesktopMd) {
        font-size: 3.75em;
    }
    @media (max-width: $sizeLaptopLg) {
        font-size: 3.125em;
    }
    @media (max-width: $sizeLaptop) {
        font-size: 2.5em;
    }
    @media (max-width: $sizeMobileMd) {
        font-size: 2.25em;
    }
    @media (max-width: $sizeMobileXs) {
        font-size: 2em;
    }
}
h3 {
    font-size: 3em;
    text-transform: capitalize;
    margin: 0;
    font-family: 'HelveticaNowDisplay', serif;
    @media (max-width: $sizeDesktopMd) {
        font-size: 2.5em;
    }
    @media (max-width: $sizeLaptopLg) {
        font-size: 2em;
    }
    @media (max-width: $sizeLaptop) {
        font-size: 1.625em;
    }
    @media (max-width: $sizeMobileMd) {
        font-size: 1.25em;
    }
}
h4 {
    font-size: 2.5em;
    text-transform: inherit;
    margin: 0;
    font-family: 'HelveticaNowDisplay', serif;
    @media (max-width: $sizeDesktopMd) {
        font-size: 2em;
    }
    @media (max-width: $sizeLaptopLg) {
        font-size: 2.25em;
    }
    @media (max-width: $sizeLaptop) {
        font-size: 1.375em;
    }
    @media (max-width: $sizeMobileMd) {
        font-size: 1.25em;
    }
}
h5 {
    font-size: 2em;
    text-transform: capitalize;
    margin: 0;
    font-family: 'HelveticaNowDisplay', serif;
    @media (max-width: $sizeDesktopMd) {
        font-size: 1.625em;
    }
    @media (max-width: $sizeLaptopLg) {
        font-size: 1.375em;
    }
}

button {
    &.cookie-btn {
        color: #000 !important;
        background: transparent;
        border: none !important;
        font-size: inherit !important;
        font-weight: 400 !important;
        border-bottom: 1.3px solid #D12FFF !important;
        padding: 0;
        cursor: pointer;
        text-align: left;
        &:focus {
            outline: none;
            box-shadow: none;
        }
        @media (max-width: $sizeMobileLg) {
            font-size: 1em;
            text-align: left;
            border-bottom: none !important;
            text-decoration: underline;
            text-decoration-color: #D12FFF;
        }
    }
}



.marquee-row {
    -webkit-animation:marquee 40s linear infinite;
    animation: marquee 40s linear infinite;
}
@keyframes marquee {
    0% {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0%);
    }
}

// Wistia video CSS start
.wistia_custom {
    width: 100% !important;
    height: 100% !important;
    >div,
    div[id^="wistia_grid_"][id$='_wrapper'] {
        width: 100% !important;
        height: 100% !important;
    }
    video {
        object-fit: cover !important;
        object-position: center top !important;
        width: calc(100% + 4px) !important;
        position: relative !important;
        left: -2px;
        border: none !important;
    }
}
.wistia_embed {
    video {
        width: calc(100% + 4px) !important;
        position: relative !important;
        left: -2px;
        border: none !important;
    }
}
// Popover Styling
.wistia_popover_mode {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
}
.wistia_popover_overlay {
    z-index: 9999999 !important;
    background-color: #000 !important;
}
.wistia_popover_embed {
    z-index: 99999999 !important;
}
.wistia_placebo_close_button {
    display: none !important;
    &.wistia_show_active_button{

        z-index: 99999999 !important;

        @media (max-width: $sizeTabletLg) {
            opacity: 1 !important;
            mix-blend-mode: difference !important;
        }
        @media (max-width: 500px) {
            left: calc(100% - 40px) !important;
            background-blend-mode: difference;
        }
        @media (max-width: 400px) {
            left: calc(100% - 35px) !important;
        }
    }
}
.wistia_popover_mode {
    .wistia_placebo_close_button {
        display: block !important;
    }
}
// Slick Remove outline CSS
.slick-slide {
    div {
        &:focus,
        &:focus-visible {
            outline: none;
        }
    }
}



div[id^="wistia-"][id$='_popover_overlay'] {
    position: fixed !important;
}


a {
    -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
}
.recaptcha-copy{
    a{
        color:inherit;
        text-decoration: underline;

    }

}

.grecaptcha-badge{
    display: none !important;
}

// .w-gallery-overlay{
//     z-index: 10000000 !important;
// }



//PARDOT FORM STYLING


/*--- Body CSS End  ---*/