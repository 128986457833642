@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
/* HelveticaNowDisplay Fonts Start */


@font-face {
    font-family: 'HelveticaNowDisplay Black';
    src: url('../assets/fonts/HelveticaNowDisplayBlack.ttf') format('truetype'),
    url('../assets/fonts/HelveticaNowDisplayBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('../assets/fonts/HelveticaNowDisplay-Bold.woff2') format('woff2'),
    url('../assets/fonts/HelveticaNowDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('../assets/fonts/HelveticaNowDisplay-Regular.woff2') format('woff2'),
    url('../assets/fonts/HelveticaNowDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('../assets/fonts/HelveticaNowDisplay-Medium.woff2') format('woff2'),
    url('../assets/fonts/HelveticaNowDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

/* HelveticaNowDisplay Fonts End */

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../assets/fonts/HelveticaNeue-Roman.woff2') format('woff2'),
    url('../assets/fonts/HelveticaNeue-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


/* Neue Pixel Grotesk */
@font-face {
    font-family: 'Neue Pixel Grotesk';
    src: url('../assets/fonts/NeuePixelGrotesk.woff2') format('woff2'),
    url('../assets/fonts/NeuePixelGrotesk.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@keyframes moveLeft {
    0% {
        transform: translateX(100vw);
        opacity: 0
    }
    100% {
        transform: translateX(0);
        opacity: 1
    }
}